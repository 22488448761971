// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

const SvgBalanceTransfer = props => (
	<svg
		data-testid="AccountBalanceIcon"
		xmlns="http://www.w3.org/2000/svg"
		width={26}
		height={25}
		fill="none"
		{...props}
	>
		<path
			fill="#333"
			fillRule="evenodd"
			d="M21.588 9.985h-4.175V8.312h4.174zM9.162 11.982l-1.338 1.335H12.9v1.66H7.788l1.375 1.36h-2.35l-2.188-2.17 2.188-2.185zm2.075 4.992 2.187 2.185-2.187 2.134h-2.35l1.374-1.323h-5.1v-1.698h5.063l-1.337-1.335zm-2.15-6.99a6.69 6.69 0 0 0-6.2 4.111 6.67 6.67 0 0 0 1.446 7.289 6.695 6.695 0 0 0 7.3 1.443 6.68 6.68 0 0 0 4.116-6.19 6.68 6.68 0 0 0-6.662-6.652m0-1.66a8.35 8.35 0 0 1 7.725 5.145A8.33 8.33 0 0 1 15 22.562a8.36 8.36 0 0 1-9.11 1.797 8.34 8.34 0 0 1-5.14-7.722c0-4.6 3.73-8.33 8.338-8.337zM25.75 2.073v12.481a2.2 2.2 0 0 1-.163.811 2.1 2.1 0 0 1-1.924 1.248H19.1a10 10 0 0 0-.15-1.635h4.713a.437.437 0 0 0 .424-.424V5.816h-17.5v1.161c-.573.148-1.134.345-1.674.587V2.072A2.086 2.086 0 0 1 7 0h16.663c1.148 0 2.08.926 2.087 2.072m-2.087-.412H7a.413.413 0 0 0-.412.412v2.084h17.5V2.072a.425.425 0 0 0-.425-.412"
			clipRule="evenodd"
		/>
	</svg>
);

export default SvgBalanceTransfer;
