// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

const SvgCard = props => (
	<svg
		data-testid="AccountBalanceWalletIcon"
		xmlns="http://www.w3.org/2000/svg"
		width="26"
		height="25"
		fill="none"
		viewBox="0 0 26 25"
		{...props}
	>
		<path
			fill="#253038"
			fillRule="evenodd"
			d="M23.167 8.333H2.333A2.09 2.09 0 0 0 .25 10.417v12.5A2.09 2.09 0 0 0 2.333 25h20.834a2.09 2.09 0 0 0 2.083-2.083v-12.5a2.09 2.09 0 0 0-2.083-2.084m.416 14.584c0 .226-.19.416-.416.416H2.333a.42.42 0 0 1-.416-.416v-8.75h21.666zm0-10.417H1.917v-2.083c0-.227.19-.417.416-.417h20.834c.226 0 .416.19.416.417zm-2.5 4.167h-4.166v1.666h4.166zM18.375 1.696q.074-.03.151-.03c.143 0 .32.081.392.26l1.915 4.74h1.798l-2.169-5.363A2.09 2.09 0 0 0 17.752.15L1.621 6.667h4.45z"
			clipRule="evenodd"
		></path>
	</svg>
);

export default SvgCard;
