"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const FormWrapper = ({ formName, children, onSubmit, isFormPending, submitButton, formFooter, style = {}, }) => {
    const theme = (0, material_1.useTheme)();
    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(event);
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '348px',
            margin: '0',
            padding: '20px 20px 34px',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            ...style,
        }, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h1", style: {
                    margin: '0',
                    marginBottom: '0.35em',
                    display: 'block',
                    backgroundColor: 'white',
                    inset: '0',
                    padding: 0,
                    zIndex: '10',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
                    lineHeight: '30px',
                    color: 'hsla(0, 0%, 0%, 1)',
                    alignSelf: 'flex-start',
                }, children: formName }), (0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, style: { boxSizing: 'border-box', width: '100%' }, children: [react_1.Children.map(children, (child) => {
                        if ((0, react_1.isValidElement)(child) && child.props.name) {
                            return (0, react_1.cloneElement)(child);
                        }
                        return child;
                    }), submitButton?.label ? ((0, jsx_runtime_1.jsx)(material_1.Button, { disabled: submitButton.isDisabled, loading: isFormPending, variant: "contained", color: "primary", type: "submit", fullWidth: true, style: {
                            marginTop: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? '22px' : '37px',
                            ...(submitButton.style && submitButton.style),
                        }, children: submitButton.label })) : null] }), formFooter ?? null] }));
};
exports.default = FormWrapper;
