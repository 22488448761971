"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cookie_1 = require("../../router/routes/cookie");
const zustandStore_1 = require("../../zustandStore");
const commonComponents_1 = require("./commonComponents");
const material_1 = require("@mui/material");
const react_router_1 = require("react-router");
const CookieCutter = () => {
    const { cookies, setIsCookieCutterOpen, setShowManageCookies, togglePreferences, toggleStatistics, toggleMarketing, acceptAll, saveConsent, } = (0, zustandStore_1.useZustand)(state => ({ ...state }));
    const { open, showManageCookies, preferences, statistics, marketing } = cookies;
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_1.useLocation)();
    return ((0, jsx_runtime_1.jsxs)(material_1.Dialog, { open: open, disableEscapeKeyDown: true, onClose: () => false, style: { zIndex: 2000 }, children: [(0, jsx_runtime_1.jsx)(material_1.DialogTitle, { children: showManageCookies ? 'Manage cookie settings' : 'Cookies on this website' }), (0, jsx_runtime_1.jsx)(material_1.DialogContent, { style: {
                    padding: '0 24px',
                    marginBottom: '20px',
                }, children: showManageCookies ? ((0, jsx_runtime_1.jsx)(material_1.Grid2, { container: true, spacing: 2, children: (0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, children: [(0, jsx_runtime_1.jsx)(commonComponents_1.BodyText, { variant: "subtitle1", children: "Cookies are small pieces of data that websites store on your browser when you visit them. Cookies are useful because they allow a website to recognise your visit and collect information about how you use that website." }), (0, jsx_runtime_1.jsx)(commonComponents_1.BodyText, { variant: "subtitle1", children: "You can choose which types of optional cookies you wish to accept. You can manage these preferences at any time below." }), (0, jsx_runtime_1.jsxs)(commonComponents_1.BodyText, { variant: "subtitle1", children: ["Please also see our", ' ', (0, jsx_runtime_1.jsx)("span", { style: { textDecoration: 'underline', cursor: 'pointer' }, onClick: e => {
                                            e.preventDefault();
                                            setIsCookieCutterOpen(false);
                                            void navigate('/cookie-notice');
                                        }, children: "Cookie notice" }), ' ', "for more information."] }), (0, jsx_runtime_1.jsx)(commonComponents_1.SectionTitle, { variant: "h6", children: "Strictly necessary cookies" }), (0, jsx_runtime_1.jsxs)(commonComponents_1.BodyText, { variant: "subtitle1", children: ["Strictly necessary cookies to ensure that our website is able 10 function properly, and include cookies that help you log in, that provide core services and features, that help keep our website secure and that help us detect fraud or crime. We don't have to ask for your consent to store these cookies on your browser. We've listed the categories of these strictly necessary cookies in our", ' ', (0, jsx_runtime_1.jsx)("span", { style: { textDecoration: 'underline', cursor: 'pointer' }, onClick: e => {
                                            e.preventDefault();
                                            setIsCookieCutterOpen(false);
                                            void navigate('/cookie-notice');
                                        }, children: "Cookie notice" }), "."] }), (0, jsx_runtime_1.jsx)(commonComponents_1.SectionTitle, { variant: "h6", children: "Optional cookies" }), (0, jsx_runtime_1.jsx)(commonComponents_1.BodyText, { variant: "subtitle1", children: "We'd also like to use cookies to provide optional features, improve our website and support advertising and social media. Where relevant, our trusted partners will help us make use of these optional cookies." }), (0, jsx_runtime_1.jsx)(commonComponents_1.BodyText, { variant: "subtitle1", children: "To make it easier 10 choose which optional cookies 10 accept, we've organised these cookies by category. These are set out below." }), (0, jsx_runtime_1.jsxs)(material_1.FormGroup, { children: [(0, jsx_runtime_1.jsx)(commonComponents_1.SectionTitle, { variant: "h5", children: "Cookies that we use to make your visit more personal" }), (0, jsx_runtime_1.jsxs)(commonComponents_1.BodyText, { variant: "subtitle1", children: ["These cookies allow optional features to work on our website. Our website will still work without them.", ' ', (0, jsx_runtime_1.jsx)("span", { style: { textDecoration: 'underline', cursor: 'pointer' }, onClick: e => {
                                                    e.preventDefault();
                                                    setIsCookieCutterOpen(false);
                                                    void navigate('/cookie-notice');
                                                }, children: "More about these cookies" }), "."] }), (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(commonComponents_1.RadioInput, { checked: preferences, onChange: togglePreferences }), label: "On" }), (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(commonComponents_1.RadioInput, { checked: !preferences, onChange: togglePreferences }), label: "Off" }), (0, jsx_runtime_1.jsx)(commonComponents_1.SectionTitle, { variant: "h5", children: "Cookies that help us improve our website" }), (0, jsx_runtime_1.jsxs)(commonComponents_1.BodyText, { variant: "subtitle1", children: ["These cookies help us understand how people use our website. We can then use this data to improve how our website works.", ' ', (0, jsx_runtime_1.jsx)("span", { style: { textDecoration: 'underline', cursor: 'pointer' }, onClick: e => {
                                                    e.preventDefault();
                                                    setIsCookieCutterOpen(false);
                                                    void navigate('/cookie-notice');
                                                }, children: "More about these cookies" }), "."] }), (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(commonComponents_1.RadioInput, { checked: statistics, onChange: toggleStatistics }), label: "On" }), (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(commonComponents_1.RadioInput, { checked: !statistics, onChange: toggleStatistics }), label: "Off" }), (0, jsx_runtime_1.jsx)(commonComponents_1.SectionTitle, { variant: "h5", children: "Cookies that support our marketing" }), (0, jsx_runtime_1.jsxs)(commonComponents_1.BodyText, { variant: "subtitle1", children: ["We and our partners use these cookies to understand what you're interested in on our website and on social media. This is so we or our partners can personalize our marketing to you.", ' ', (0, jsx_runtime_1.jsx)("span", { style: { textDecoration: 'underline', cursor: 'pointer' }, onClick: e => {
                                                    e.preventDefault();
                                                    setIsCookieCutterOpen(false);
                                                    void navigate('/cookie-notice');
                                                }, children: "More about these cookies" }), "."] }), (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(commonComponents_1.RadioInput, { checked: marketing, onChange: toggleMarketing }), label: "On" }), (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(commonComponents_1.RadioInput, { checked: !marketing, onChange: toggleMarketing }), label: "Off" })] })] }) })) : ((0, jsx_runtime_1.jsx)(material_1.Grid2, { container: true, spacing: 2, children: (0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, children: [(0, jsx_runtime_1.jsx)(commonComponents_1.BodyText, { variant: "subtitle1", children: "We use cookies to help ensure that our website and services are able to function properly. These cookies are necessary and so are set automatically." }), (0, jsx_runtime_1.jsx)(commonComponents_1.BodyText, { variant: "subtitle1", children: "We would like to use some optional cookies to:" }), (0, jsx_runtime_1.jsx)(commonComponents_1.StyledBulleList, { variant: "subtitle1", children: "Make your visit more personal" }), (0, jsx_runtime_1.jsx)(commonComponents_1.StyledBulleList, { variant: "subtitle1", children: "Improve our website based on how you use it" }), (0, jsx_runtime_1.jsx)(commonComponents_1.StyledBulleList, { variant: "subtitle1", children: "Support our marketing" }), (0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: "subtitle1", children: ["To learn more about how we use cookies, visit our", ' ', (0, jsx_runtime_1.jsx)("span", { style: { textDecoration: 'underline', cursor: 'pointer' }, onClick: e => {
                                            e.preventDefault();
                                            setIsCookieCutterOpen(false);
                                            void navigate('/cookie-notice');
                                        }, children: "Cookie notice" }), "."] })] }) })) }), (0, jsx_runtime_1.jsx)(material_1.DialogActions, { style: { justifyContent: 'flex-start', padding: '0 24px 24px ' }, children: showManageCookies ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, { variant: "contained", color: "primary", onClick: () => {
                                saveConsent();
                                setShowManageCookies(false);
                                setIsCookieCutterOpen(false);
                            }, style: { padding: '15px 43px' }, children: "Save cookie settings" }), location.pathname === cookie_1.cookieNoticePath ? null : ((0, jsx_runtime_1.jsx)(material_1.Button, { variant: "contained", color: "secondary", style: { padding: '15px 43px' }, onClick: () => setShowManageCookies(false), children: "Back" }))] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, { variant: "contained", color: "primary", style: { padding: '15px 43px' }, onClick: () => setShowManageCookies(true), children: "Manage cookie settings" }), (0, jsx_runtime_1.jsx)(material_1.Button, { variant: "contained", style: {
                                backgroundColor: 'white',
                                outline: '1px solid black',
                                outlineStyle: 'inset',
                                outlineOffset: '-1px',
                                padding: '15px 43px',
                            }, color: "secondary", onClick: () => {
                                acceptAll();
                                saveConsent();
                            }, children: "Accept all cookies" })] })) })] }));
};
exports.default = CookieCutter;
