"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioInput = exports.SectionTitle = exports.BodyText = exports.StyledBulleList = void 0;
const material_1 = require("@mui/material");
exports.StyledBulleList = (0, material_1.styled)(material_1.Typography)(ownerState => {
    return {
        lineHeight: 'normal',
        marginTop: ownerState.theme.spacing(1),
        marginLeft: ownerState.theme.spacing(2),
        position: 'relative',
        ...(ownerState['aslink'] === 'true' && {
            textDecoration: 'underline',
            cursor: 'pointer',
        }),
        '&::before': {
            content: '"•"',
            position: 'absolute',
            left: `calc(-${ownerState.theme.spacing(2)})`,
            top: '1.5px',
        },
    };
});
exports.BodyText = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    lineHeight: 'normal',
    marginTop: theme.spacing(1),
    marginBottom: '0',
}));
exports.SectionTitle = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    lineHeight: 'normal',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: `0 0 ${theme.spacing(1)} 0`,
}));
exports.RadioInput = (0, material_1.styled)(material_1.Radio)(({ theme }) => ({
    color: `${theme.palette.tsGoColor.greenDark} !important`,
}));
