"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const approvalsUtils_1 = require("../../../../queries/approvals/approvalsUtils");
const zustandStore_1 = require("../../../../zustandStore");
const useFormattedDate_1 = require("../../request/hooks/useFormattedDate");
const approvalHelpers_1 = require("../approvalHelpers");
const approvalItems_1 = require("../components/approvalItems");
const approvalStatusToColor = (theme) => ({
    pending: theme.palette['tsGoColor'].yellowDarker,
    approved: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? theme.palette['hsbcColor'].green
        : theme.palette['tsGoColor'].green,
    declined: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? theme.palette['hsbcColor'].primary
        : theme.palette['tsGoColor'].red,
    canceled: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? theme.palette['hsbcColor'].primary
        : theme.palette['tsGoColor'].red,
});
const ApprovalItem = ({ approvalItem, children }) => {
    const theme = (0, material_1.useTheme)();
    const activeHeaderTab = (0, zustandStore_1.useZustand)(state => state.header.activeHeaderTab);
    const currentActiveApprovalTab = activeHeaderTab || 'pending';
    const { request } = approvalItem;
    const { requester, amount, currency, title, lastEdit } = request;
    const { firstName, lastName } = requester;
    const approvalState = (0, approvalsUtils_1.getApprovalStatus)(approvalItem);
    const approvalStatus = approvalHelpers_1.approvalStatusToLabel[approvalState] === activeHeaderTab ? approvalHelpers_1.approvalStatusToLabel[approvalState] : activeHeaderTab;
    const approvalStatusTextColor = approvalStatusToColor(theme)[currentActiveApprovalTab];
    const formattedDate = (0, useFormattedDate_1.useFormattedDate)(lastEdit);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',
    });
    return ((0, jsx_runtime_1.jsxs)(approvalItems_1.ItemWrapper, { children: [(0, jsx_runtime_1.jsxs)(material_1.Box, { style: {
                    gap: theme.tsGoUnit.tsUnit,
                }, children: [(0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: "body1", style: {
                            fontSize: theme.typography.tsFontSize.default,
                            ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                                ? {
                                    color: '#000000',
                                    fontSize: theme.typography.tsFontSize.mini,
                                }
                                : {}),
                        }, children: [(0, jsx_runtime_1.jsxs)("span", { children: [firstName, " ", lastName] }), ' ', "requests ", (0, jsx_runtime_1.jsx)("strong", { children: formatter.format(amount) })] }), (0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: "body2", style: { fontSize: theme.typography.tsFontSize.mini }, children: [(0, jsx_runtime_1.jsx)("strong", { style: { color: approvalStatusTextColor }, children: approvalStatus?.toUpperCase() }), " \u00A0\u2022\u00A0", ' ', title, " \u00A0\u2022\u00A0 ", formattedDate] })] }), children] }));
};
exports.default = ApprovalItem;
