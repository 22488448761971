"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settlementInitialState = void 0;
exports.settlementSlice = settlementSlice;
const appConstants_1 = require("../../constants/appConstants");
const date = new Date();
const currentYear = date.getFullYear();
exports.settlementInitialState = {
    settlement: {
        settlements: [],
        filters: {
            parentCard: 'allSourceCards',
            virtualCard: 'allVirtualCards',
            month: String(appConstants_1.months?.[date.getMonth()]?.value),
            year: String(currentYear),
            showTransactionWithoutReceipts: false,
        },
        areSettlementFiltersManuallySet: false,
        options: {
            parentCards: [],
            virtualCards: [],
            months: [],
            years: [],
        },
        page: 1,
        isReceiptUploadRemove: false,
    },
};
const compareSettlementDates = (a, b, sortBy) => {
    const dateA = new Date(a.date ?? '').getTime();
    const dateB = new Date(b.date ?? '').getTime();
    return sortBy === 'most-recent' ? dateB - dateA : dateA - dateB;
};
function settlementSlice(set) {
    return {
        ...exports.settlementInitialState,
        setSettlements(settlements, sortBy = 'most-recent') {
            set((state) => {
                const sortedSettlements = [...settlements]?.sort((a, b) => compareSettlementDates(a, b, sortBy));
                state.settlement.settlements = sortedSettlements;
                return state;
            }, false, '[setSettlements]');
        },
        resetSettlementFilters() {
            set((state) => {
                state.settlement.filters.parentCard = exports.settlementInitialState.settlement.filters.parentCard;
                state.settlement.filters.virtualCard = exports.settlementInitialState.settlement.filters.virtualCard;
                state.settlement.filters.month = exports.settlementInitialState.settlement.filters.month;
                state.settlement.filters.year = exports.settlementInitialState.settlement.filters.year;
                state.settlement.filters.showTransactionWithoutReceipts =
                    exports.settlementInitialState.settlement.filters.showTransactionWithoutReceipts;
                return state;
            }, false, '[resetSettlementFilters]');
        },
        setSettlementFilters(filters) {
            set((state) => {
                state.settlement.filters = filters;
                return state;
            }, false, '[setSettlementFilters]');
        },
        setSettlementOptions(options) {
            set((state) => {
                state.settlement.options = options;
                return state;
            }, false, '[setSettlementOptions]');
        },
        setPage(page) {
            set((state) => {
                state.settlement.page = page;
                return state;
            }, false, '[setPage]');
        },
        setAreSettlementFiltersManuallySet(areSettlementFiltersManuallySet) {
            set((state) => {
                state.settlement.areSettlementFiltersManuallySet = areSettlementFiltersManuallySet;
                return state;
            }, false, '[setAreSettlementFiltersManuallySet]');
        },
        setIsReceiptUploadRemove(isReceiptUploadRemove) {
            set((state) => {
                state.settlement.isReceiptUploadRemove = isReceiptUploadRemove;
                return state;
            }, false, '[setIsReceiptUploadRemove]');
        },
    };
}
