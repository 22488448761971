// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

const SvgCreditLimitIncrease = props => (
	<svg
		data-testid="PaidIcon"
		xmlns="http://www.w3.org/2000/svg"
		width="26"
		height="25"
		fill="none"
		viewBox="0 0 26 25"
		{...props}
	>
		<path
			fill="#253038"
			fillRule="evenodd"
			d="M21.976 0H5.314a2.087 2.087 0 0 0-2.088 2.05V13.9l1.675-1.65V5.8h17.5v8.75a.425.425 0 0 1-.425.412h-9.162l1.637 1.675h7.5a2.087 2.087 0 0 0 2.113-2.087V2.05A2.087 2.087 0 0 0 21.976 0m-2.075 8.3V10h-4.175V8.3zm2.075-6.663c.23 0 .419.183.425.413v2.088h-17.5V2.05c0-.228.185-.412.413-.412zM2.14 18.164v2.35L6.85 15.8V25h1.675v-9.2l4.713 4.712v-2.35l-5.55-5.55z"
			clipRule="evenodd"
		></path>
	</svg>
);
export default SvgCreditLimitIncrease;
