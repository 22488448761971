// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

const SvgCardDetails = props => (
	<svg
		data-testid="CreditScoreIcon"
		xmlns="http://www.w3.org/2000/svg"
		width="26"
		height="25"
		fill="none"
		viewBox="0 0 26 25"
		{...props}
	>
		<path
			fill="#333"
			fillRule="evenodd"
			d="M23.163 0H6.5a2.087 2.087 0 0 0-2.087 2.086v5.498a9.5 9.5 0 0 1 1.675-.588V5.835h17.5v8.745a.425.425 0 0 1-.425.412h-4.725q.141.826.15 1.662h4.575a2.075 2.075 0 0 0 2.087-2.074V2.086A2.087 2.087 0 0 0 23.163 0m.424 4.16h-17.5V2.086c0-.23.183-.418.413-.424h16.663c.234 0 .424.19.424.424zm-2.5 4.173v1.662h-4.174V8.333zM8.225 12.556a.99.99 0 0 1 1.25.55.974.974 0 1 1-1.812.712.987.987 0 0 1 .562-1.262m1.25 2.711v5.56H7.75v-5.56zm-.887-5.272a6.675 6.675 0 0 0-6.177 4.113 6.67 6.67 0 0 0 1.444 7.276 6.68 6.68 0 0 0 7.28 1.444A6.672 6.672 0 0 0 8.588 9.995m0-1.662a8.34 8.34 0 0 1 7.704 5.147 8.33 8.33 0 0 1-1.813 9.083 8.34 8.34 0 0 1-9.09 1.799A8.33 8.33 0 0 1 .25 16.654a8.32 8.32 0 0 1 8.338-8.32"
			clipRule="evenodd"
		></path>
	</svg>
);
export default SvgCardDetails;
